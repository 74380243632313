<template>
  <common-form
    name="contacts"
    @submit="submitData"
  >
    <pp-frame>
      <template #message>
        <h2 class="blue-grey--text">
          <p>{{ $t('Medical Contacts') }}</p>
        </h2>

        <div class="secondary--text">
          {{ translate(`
          We care about providing the best possible treatment to you.
          `) }}
          {{ translate(`
          We regularly contact your other medical providers
          to make them aware of your progress throughout treatment.
          `) }}
          {{ translate(`
          This helps ensure all your medical providers coordinate care
          to maximize the effectiveness of your treatment.
          `) }}
          {{ translate(`
          Please list your other healthcare providers here.
          `) }}
        </div>
      </template>
      <dialog-confirm ref="confirmDialog" />
      <v-row class="flex-grow-0">
        <v-col
          v-for="(contact, idx) in contacts"
          :key="`contact-${idx}`"
          cols="12"
        >
          <v-autocomplete
            v-if="contact.docId || !(contact.patientContact)"
            v-model="contact.docId"
            :label="contact.label"
            :items="contact.candidates"
            :search-input.sync="contact.contactName"
            item-text="name"
            item-value="id"
            :placeholder="contactNameLabel"
            no-filter
            dense
            @click="() => updateCandidates(idx)"
            @update:search-input="(e) => updateCandidates(idx, e)"
          >
            <template #append-item>
              <div>
                <v-divider />
                <v-list-item @click="() => createCustomContact(idx)">
                  <v-list-item-title class="d-flex align-center justify-center">
                    <v-icon class="mr-2">
                      mdi-account-plus
                    </v-icon>
                    <span>{{ $t('Create New Contact') }}</span>
                  </v-list-item-title>
                </v-list-item>
              </div>
            </template>
          </v-autocomplete>
          <common-form
            v-else
            @submit="() => saveContact(idx)"
          >
            <input
              type="hidden"
              class="customContactId"
              :value="idx"
            >
            <p>
              {{ contact.label }}
            </p>
            <v-row no-gutters>
              <v-col
                class="px-2"
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="contact.patientContact.firstname"
                  :label="contactFirstnameLabel"
                  dense
                  :rules="[$validators.isValid()]"
                />
              </v-col>
              <v-col
                class="px-2"
                cols="12"
                sm="6"
              >
                <v-text-field
                  v-model="contact.patientContact.lastname"
                  :label="contactLastnameLabel"
                  dense
                  :rules="[$validators.isValid()]"
                />
              </v-col>
              <v-col
                class="px-2"
                cols="12"
              >
                <v-text-field
                  v-model="contact.patientContact.address1"
                  :label="contactAdd1Label"
                  :rules="[$validators.isValid()]"
                  dense
                />
              </v-col>
              <v-col
                class="px-2"
                cols="12"
              >
                <v-text-field
                  v-model="contact.patientContact.address2"
                  :label="contactAdd2Label"
                  dense
                />
              </v-col>
              <v-col
                class="px-2"
                cols="12"
                sm="8"
              >
                <v-text-field
                  v-model="contact.patientContact.city"
                  :label="contactCityLabel"
                  :rules="[$validators.isValid()]"
                  dense
                />
              </v-col>
              <v-col
                class="px-2"
                cols="6"
                sm="4"
              >
                <v-select
                  v-model="contact.patientContact.state"
                  :label="contactStateLabel"
                  :items="states"
                  :rules="[$validators.isValid()]"
                  dense
                />
              </v-col>
              <v-col
                class="px-2"
                cols="6"
                sm="4"
              >
                <v-text-field
                  v-model="contact.patientContact.zip"
                  v-mask="'#####-####'"
                  :label="contactZipLabel"
                  :rules="[$validators.isZipCode()]"
                  dense
                />
              </v-col>
              <v-col
                class="px-2"
                cols="12"
                sm="8"
              >
                <v-text-field
                  v-model="contact.patientContact.phone"
                  v-mask="'(###) ###-####'"
                  :label="contactPhoneLabel"
                  autocomplete="off"
                  :rules="[!contact.patientContact.phone ||
                    $validators.isValidPhone()]"
                  dense
                />
              </v-col>
              <v-col
                v-if="contact.patientContact.id"
                class="d-flex"
                cols="12"
              >
                <v-btn
                  color="primary"
                  text
                  type="submit"
                >
                  {{ $t('Save') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  @click="confirmCustomContactDeletion(idx)"
                >
                  {{ $t('Delete') }}
                </v-btn>
              </v-col>
              <v-col
                v-else
                class="d-flex"
                cols="12"
              >
                <v-btn
                  color="primary"
                  text
                  type="submit"
                >
                  {{ $t('Create') }}
                </v-btn>
                <v-spacer />
                <v-btn
                  color="primary"
                  text
                  @click="() => removeCustomContact(idx)"
                >
                  {{ $t('Cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </common-form>
        </v-col>
      </v-row>

      <template #actions>
        <v-row class="ma-0">
          <v-btn to="/register/employer">
            <v-icon>mdi-chevron-double-left</v-icon>
            {{ $t('Back') }}
          </v-btn>

          <v-spacer />

          <v-btn
            color="primary"
            type="submit"
            :loading="updatingProfile"
          >
            {{ $t('Proceed') }}
            <v-icon>mdi-chevron-double-right</v-icon>
          </v-btn>
        </v-row>
      </template>
    </pp-frame>
  </common-form>
</template>

<script>
import { onMounted, ref, computed } from '@vue/composition-api'
import { utils, events, toastr, VueI18nManager } from 'ui-common'
import { useProfile } from '@/use/profile'
import { states, PageContacts } from '@/constants'
import { useQuestionnaire } from '@/use/questionnaire'

const useContacts = () => {
  const confirmDialog = ref(null)
  const translationManager = VueI18nManager.getInstance()

  const {
    primaryProfile,
    updatingProfile,
    updateSecondaryProfile,

    contacts,
    getPatientContacts,
    getContactCandidates,
    saveContact,
    deletePatientContact
  } = useProfile()

  const {
    getCompletedSections
  } = useQuestionnaire()

  const createCustomContact = (idx) => {
    const { patientid } = primaryProfile.value
    const { typeId } = contacts.value[idx]

    contacts.value[idx].tempId = contacts.value[idx].docId
    contacts.value[idx].docId = ''

    contacts.value[idx].patientContact = {
      patientid,
      contacttype: typeId,
      firstname: '',
      lastname: '',
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
      phone: ''
    }
  }

  const removeCustomContact = async (idx) => {
    const patientContactId = contacts.value[idx]?.patientContact?.id
    if (patientContactId) {
      try {
        await deletePatientContact({ id: patientContactId })
      } catch (err) {
        const message = err?.response?.data?.message
        if (message) {
          toastr.e(message)
        }
        return
      }
    }
    contacts.value[idx].docId = contacts.value[idx].tempId || ''
    contacts.value[idx].patientContact = null
  }

  const confirmCustomContactDeletion = async (id) => {
    const result = await confirmDialog.value.open({
      title: translationManager.t('Confirm'),
      message: translationManager
        .t('Are you sure you want to delete this contact?')
    })
    if (result) {
      await removeCustomContact(id)
    }
  }

  const updateCandidates = (idx, e) => {
    const { docid } = primaryProfile.value

    if (e !== undefined &&
      contacts.value[idx].keyword === contacts.value[idx].contactName) {
      return
    }
    contacts.value[idx].keyword = contacts.value[idx].contactName

    getContactCandidates({
      keyword: contacts.value[idx].keyword
        ? contacts.value[idx].keyword.split(' ').filter(k => k).reverse() : '',
      idx,
      docid
    })
  }

  const submitData = async () => {
    const errorsList = document.querySelectorAll('div.v-messages__message')

    if (errorsList.length) {
      toastr.e(translationManager.t(
        'The form data is invalid. Please, check error messages.'
      ))
      return
    }

    const customContacts = document.getElementsByClassName('customContactId')

    for (const customContact of customContacts) {
      if (!customContact) {
        continue
      }

      const customContactId = customContact.value
      const customContactFrom = customContact.parentElement

      if (!customContactId || !customContactFrom) {
        continue
      }

      if (window.getComputedStyle(customContactFrom).display === 'none') {
        continue
      }

      const patientContact = contacts
        .value[customContactId]
        .patientContact

      if (typeof patientContact.id === 'undefined') {
        toastr.e(translationManager.t(
          'Please, complete or cancel custom contacts creating.'
        ))
        return
      }
    }

    try {
      await updateSecondaryProfile({
        info: {
          lastRegSect: PageContacts
        },
        updatecontacts: true
      })
      await getCompletedSections()
      utils.navigate('/register/congrats')
    } catch (err) {}
  }

  onMounted(() => {
    getPatientContacts()
  })

  return {
    contacts,
    createCustomContact,
    removeCustomContact,
    updateCandidates,
    updatingProfile,
    submitData,
    saveContact,
    confirmCustomContactDeletion,
    confirmDialog
  }
}

export default {
  setup: (props, context) => {
    const { root } = context

    const {
      contacts,
      createCustomContact,
      removeCustomContact,
      updateCandidates,
      updatingProfile,
      submitData,
      saveContact,
      confirmCustomContactDeletion,
      confirmDialog
    } = useContacts()

    onMounted(() => {
      root.$nextTick(() => {
        events.emit('form.reset.contacts')
      })
    })

    return {
      contacts,
      createCustomContact,
      removeCustomContact,
      updateCandidates,
      updatingProfile,
      submitData,
      saveContact,
      confirmDialog,
      confirmCustomContactDeletion,
      states,

      contactNameLabel: computed(() =>
        root.$i18n.t('Type Doctor Name')
      ),
      contactFirstnameLabel: computed(() =>
        root.$i18n.t('First Name')
      ),
      contactLastnameLabel: computed(() =>
        root.$i18n.t('Last Name')
      ),
      contactAdd1Label: computed(() =>
        root.$i18n.t('Address1')
      ),
      contactAdd2Label: computed(() =>
        root.$i18n.t('Address2')
      ),
      contactCityLabel: computed(() =>
        root.$i18n.t('City')
      ),
      contactPhoneLabel: computed(() =>
        root.$i18n.t('Phone')
      ),
      contactZipLabel: computed(() =>
        root.$i18n.t('Zip')
      ),
      contactStateLabel: computed(() =>
        root.$i18n.t('State')
      )
    }
  }
}
</script>
